import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  FormGroup,
  Badge,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import useCloudinary from "hooks/useCloudinaryImage"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions
import { getProductPerformance } from "store/performance/actions"

import "../../assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSiteVersion } from "hooks/useSiteVersion"
import { defaultImg } from "assets/images"

const ProductPerformance = () => {
  const dispatch = useDispatch()
  const { isPremium } = useSiteVersion()
  const { productPerformanceData, loading } = useSelector(state => ({
    productPerformanceData: state?.Performance?.productPerformanceData,
    loading: state?.Performance?.loading,
  }))

  const total = 10

  const [orderSearchText, setOrderSearchText] = useState("")
  // const [sort, setSort] = useState("all")
  const [isCustomStore, setIsCustomStore] = useState(false)

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  //pagination
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const [totalItemLimit, setTotalItemLimit] = useState(10)

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  console.log(totalItemLimit)

  useEffect(() => {
    dispatch(
      getProductPerformance(
        page,
        totalItemLimit,
        orderSearchText,
        handleDateSearch()
      )
    )
  }, [dispatch, totalItemLimit,page, orderSearchText, finalDateFormated])

  const columns = [
    {
      dataField: "imageData",
      text: "Product",
    },
    {
      dataField: "productName",
      text: "",
    },
    {
      dataField: "orderQuantity",
      text: "Orders",
    },
    {
      dataField: "averageRating",
      text: "Rating",
    },
    {
      dataField: "cartQuantity",  
      text: "Carts",
    },
    {
      dataField: "favouriteCount",
      text: "Favourites",
    },
    {
      dataField: "recentViewCount",
      text: "Views",
    },
    {
      dataField: "negativePoints",
      text: "- Marks",
    },
    {
      dataField: "performanceIndex",
      text: "Index",
    },
    {
      dataField: "performancePercentage",
      text: "Percentage",
    },
  ]

  const orderData = map(productPerformanceData, item => ({
    ...item,
    productName: (
      <Link
        to={`/product/update/${item?.product._id}`}
      >{`${item?.product?.itemCode} - ${item?.product?.name}`}</Link>
    ),
    productName: (
      <Row>
        <Link to={`/product/update/${item?.product._id}`}>
          {item?.product?.itemCode +
            " - " +
            (item?.product?.name.length > 40
              ? item?.product?.name.substr(0, 50 - 1) + "..."
              : item?.product?.name)}
        </Link>
      </Row>
    ),
    imageData: (
      <>
        <img
          // src={item?.product?.image ? item?.product?.image : defaultImg}
          src={
            item?.product?.image
              ? useCloudinary(item?.product?.image, "c_thumb,w_100")
              : defaultImg
          }
          width="50px"
        />
      </>
    ),
    // orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    // createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    // paymentType: (
    //   <>
    //     {item?.paymentType === "Cash on Delivery" ? (
    //       <Badge className="px-2 py-2 badge-soft-info ">COD</Badge>
    //     ) : (
    //       <Badge className="px-2 py-2 badge-soft-success ">
    //         {item?.paymentType}
    //       </Badge>
    //     )}
    //     &nbsp;
    //     {item?.isPaid ? (
    //       <Badge className="px-2 py-2 badge-soft-success ">Paid</Badge>
    //     ) : (
    //       <Badge className="px-2 py-2 badge-soft-danger">Not Paid</Badge>
    //     )}
    //   </>
    // ),
    // orderStatus: (
    //   <Badge
    //     className={
    //       "px-2 py-2 badge-soft-" + `${handleOrderStatus(item?.orderStatus)}`
    //     }
    //   >
    //     {" "}
    //     {item?.orderStatus}{" "}
    //   </Badge>
    // ),
    // activeData: (
    //   <>
    //     {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
    //     <div className="form-check form-switch form-switch-md mb-3">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         onChange={() => {
    //           handleSwitchChange(item?._id)
    //         }}
    //         checked={item?.isActive}
    //       />
    //       <label className="form-check-label" htmlFor="customSwitchsizemd">
    //         {item?.isActive ? `Active` : `Inactive`}
    //       </label>
    //     </div>
    //   </>
    // ),
    // action: (
    //   <div>
    //     <Link to={`/order/${item?._id}`}>
    //       <Button id={`view`} type="button" color="white" className="ms-1 btn">
    //         <i
    //           className="bx bx-show-alt me-2"
    //           style={{ color: "green", fontSize: "22px" }}
    //         />
    //       </Button>
    //     </Link>
    //     {/* <Link to={`/order/${item?._id}`} className="btn-light btn-sm text-info">
    //       View Details
    //     </Link> */}
    //   </div>
    // ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "radio",
  }

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  // const [selectedGroup, setselectedGroup] = useState(null)
  // const [totalPage, setTotalPage] = useState(10)

  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup)
  //   setTotalPage(selectedGroup?.value)
  // }

  // const optionGroup = [
  //   {
  //     // label: "Picnic",
  //     options: [
  //       { label: "10", value: "10" },
  //       { label: "20", value: "20" },
  //       { label: "50", value: "50" },
  //       { label: "100", value: "100" },
  //     ],
  //   },
  // ]

  // const handleSelect = v => {
  //   setTotalPage(v)
  // }

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
    }
  }

  // const printValue = () => {
  //   debounce(value => setStoreSearchText(value), 500)
  // }

  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)
  // function handleEnters(textEntered) {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerStoreFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  // const listChange = sortValue => {
  //   setSort(sortValue)
  // }

  // const printValue = debounce(value => setFinalInput(value), 500)

  // const handleChangeSearch = e => {
  //   printValue(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card className="pt-3">
            <CardBody>
              {/* <Row>
                <div className="col-md-12">
                  <Link to="/orders/new"
                    style={{ width: "60px" }}
                    className="w-md btn btn-primary m-auto me-0 d-block"
                  >
                  + New Order
                  </Link>
                </div>
              </Row> */}
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col className="col-12 d-flex align-items-center mb-3">
                        <div className="search-box ms-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>

                        <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalItemLimit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                        <div className=" d-flex align-items-center ">
                          <p
                            className="mb-0 pb-0 mx-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Date :
                          </p>
                          <AvForm>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </div>
                      </Col>{" "}
                      {/* <Col xl={4} lg={6} md={6} sm={6} className="">
                        <FormGroup className="w-100 custom_store_select">
                          <div className="mb-3 ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleEnters}
                              value={selectedStore}
                              placeholder={selectedStore}
                              onChange={handlerStoreFinalValue}
                              options={storeOptions}
                              classNamePrefix="select-selection"
                              isLoading={StoreLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {productPerformanceData?.length ? (
                          <Row>
                            {/* <p>Total Orders :&nbsp;{total ? total : 0} </p> */}

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // keyField={"id"}
                                  key={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Performance Data!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProductPerformance
