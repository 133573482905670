import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, Modal, ModalBody, Row, FormGroup } from "reactstrap"
import { createLsg, getAllDistricts, updateLsg } from "store/actions"
import Select from "react-select"
import { debounce } from "lodash"

const LsgModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const [districtSearch, setDistrictSearch] = useState("")
  const [districtSelect, setDistrictSelect] = useState("Search District")
  const [districtSelectId, setDistrictSelectId] = useState("")

  const { loading, districts } = useSelector(state => ({
    loading: state.Blogs.loading,
    districts: state.Location.districts,
  }))
  // console.log(updateData?._id)
  const handleValidSubmit = (e, v) => {
    const lsg = {
      ...v,
      district: districtSelectId,
    }

    if (updateData?._id) {
      dispatch(updateLsg(updateData?._id, lsg, onCloseClick))
    } else {
      dispatch(createLsg(lsg, onCloseClick))
    }
  }

  useEffect(() => {
    dispatch(getAllDistricts(districtSearch, "", "", 10))
  }, [dispatch, districtSearch])
  const debounceDistrictSearch = debounce(
    value => setDistrictSearch(value),
    600
  )
  const handleDistrictEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }
  function handlerDistrictFinalValue(event) {
    setDistrictSelect(event.label)
    setDistrictSelectId(event.value)
  }
  const DistrictOptions = [
    {
      label: "All district",
      id: "",
    },
    {
      options: (districts?.total >= 1 ? districts?.districts : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>{updateData?._id ? "Update" : "Add New"} Lsg</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="name"
                type="text"
                placeholder="Lsg name"
                value={updateData?.name}
                className="mb-3"
              />
            </Col>
            <Col className="mb-2 padding-3" lg={12}>
              <FormGroup className="w-350">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleDistrictEnters}
                    value={districtSelect}
                    placeholder={districtSelect}
                    onChange={handlerDistrictFinalValue}
                    options={DistrictOptions}
                    classNamePrefix="select2-selection"
                    isLoading={loading}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

LsgModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default LsgModal

const deleteModalStyle = {
  width: 300,
  minWidth: 150,
}
const deleteModalBodyStyle = {
  minHeight: 200,
}
