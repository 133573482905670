import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS_LIST,
  GET_ALL_PRODUCTS_LIST_SUCCESS,
  GET_ALL_PRODUCTS_LIST_FAIL,
  GET_DELETED_PRODUCTS,
  GET_DELETED_PRODUCTS_SUCCESS,
  GET_DELETED_PRODUCTS_FAIL,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  PRODUCT_SINGLE_VIEW_STATE_CLEAR,
  PRODUCT_SINGLE_VIEW_STATE_CLEAR_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_SUCCESS,
  CHANGE_PRODUCT_STATUS_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  HARD_DELETE_PRODUCT,
  HARD_DELETE_PRODUCT_SUCCESS,
  HARD_DELETE_PRODUCT_FAIL,
  RESTORE_PRODUCT,
  RESTORE_PRODUCT_SUCCESS,
  RESTORE_PRODUCT_FAIL,
  CREATE_DUPLICATE_PRODUCT,
  CREATE_DUPLICATE_PRODUCT_SUCCESS,
  CREATE_DUPLICATE_PRODUCT_FAIL,
  UNDO_REFRASH,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD,
  UPDATE_BULK_PRODUCTS_SUCCESS,
  UPDATE_BULK_PRODUCTS_FAIL,
  UPDATE_BULK_PRODUCTS,
  CHECK_PRODUCT_NAME,
  CHECK_PRODUCT_NAME_SUCCESS,
  CHECK_PRODUCT_NAME_FAIL,
  GET_REVIEWS,
  GET_REVIEW_DETAILS,
  CREATE_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
  GET_REVIEWS_FAIL,
  GET_REVIEW_DETAILS_FAIL,
  CREATE_REVIEW_FAIL,
  UPDATE_REVIEW_FAIL,
  DELETE_REVIEW_FAIL,
  GET_REVIEWS_SUCCESS,
  GET_REVIEW_DETAILS_SUCCESS,
  CREATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  GET_PRODUCT_SORT_COUNT,
  GET_PRODUCT_SORT_COUNT_FAIL,
  GET_PRODUCT_SORT_COUNT_SUCCESS,
  PRODUCT_SINGLE_VIEW_STATE_CLEAR_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  products: {
    products: [],
    total: "",
    page: "",
  },
  productsForDownload: {
    products: [],
    total: 0,
  },
  productSortCount: {},
  productsForDownloadLoading: false,
  productsList: [],
  productsListTotal: 0,
  productDetails: {},
  productVarients: {},
  error: {},
  loading: false,
  createProductError: null,
  needRefrash: false,
  updateBulkProductsLoading: false,
  checkProductNames: {},

  reviews: [],
  reviewsDetails: {},
  reviewLoad: false,
}

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
    case GET_ALL_PRODUCTS:
    case GET_ALL_PRODUCTS_LIST:
    case GET_DELETED_PRODUCTS:
    case GET_PRODUCT_DETAILS:
    case CREATE_PRODUCT:
    case UPDATE_PRODUCT:
    case CHANGE_PRODUCT_STATUS:
    case DELETE_PRODUCT:
    case HARD_DELETE_PRODUCT:
    case RESTORE_PRODUCT:
    case CREATE_DUPLICATE_PRODUCT:
    case GET_REVIEWS:
      case PRODUCT_SINGLE_VIEW_STATE_CLEAR:

    case GET_REVIEW_DETAILS:
    case GET_PRODUCT_SORT_COUNT:

      return {
        ...state,
        loading: true,
      }
    case CREATE_REVIEW:
    case UPDATE_REVIEW:
    case DELETE_REVIEW:
      return {
        ...state,
        reviewLoad: true,
      }

    case GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        productsForDownloadLoading: false,
      }

    case CREATE_PRODUCT_FAIL:
      return {
        ...state,
        createProductError: action.payload,
        loading: false,
      }
    case GET_PRODUCT_SORT_COUNT_FAIL:
      return {
        ...state,
        productSortCount: action.payload,
        loading: false,
      }

    case UPDATE_BULK_PRODUCTS_FAIL:
      return {
        ...state,
        updateBulkProductsLoading: false,
      }

    case CREATE_DUPLICATE_PRODUCT_FAIL:
      return {
        ...state,
        createProductError: action.payload,
        loading: false,
        needRefrash: false,
      }

    case HARD_DELETE_PRODUCT_FAIL:
    case RESTORE_PRODUCT_FAIL:
    case DELETE_PRODUCT_FAIL:
    case GET_ALL_PRODUCTS_FAIL:
    case GET_ALL_PRODUCTS_LIST_FAIL:
    case GET_DELETED_PRODUCTS_FAIL:
    case GET_PRODUCT_DETAILS_FAIL:
    case GET_PRODUCTS_FAIL:
    case CHANGE_PRODUCT_STATUS_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case GET_REVIEWS_FAIL:
      case PRODUCT_SINGLE_VIEW_STATE_CLEAR_FAIL:
    case GET_REVIEW_DETAILS_FAIL:

      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_REVIEW_FAIL:
    case UPDATE_REVIEW_FAIL:
    case DELETE_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        reviewLoad: false,
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        error: {},
        loading: false,
      }

    case GET_PRODUCT_SORT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        productSortCount: action.payload,
      }

    case GET_ALL_PRODUCTS_FOR_DOWNLOAD:
      return {
        ...state,
        productsForDownloadLoading: true,
      }
    case UPDATE_BULK_PRODUCTS:
      return {
        ...state,
        updateBulkProductsLoading: true,
      }

    //all
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        total: action.payload.total,
        error: {},
        loading: false,
      }

    //all LIST
    case GET_ALL_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productsList: action.payload.products,
        productsListTotal: action.payload.total,
        error: {},
        loading: false,
      }

    case GET_DELETED_PRODUCTS_SUCCESS:
      return {
        ...state,
        deletedProducts: action.payload.data,
        error: {},
        loading: false,
      }

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: action.payload,
        productVarients: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        // products: [state.products, action.payload],
        createProductError: null,
        error: {},
        loading: false,
      }

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        // products: state.products?.products?.map(product =>
        //   product._id.toString() === action.payload._id.toString()
        //     ? { product, ...action.payload }
        //     : product
        // ),
        loading: false,
        error: {},
        productDetails: action.payload,
      }

    case CHANGE_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        products: [...state.products].map(product =>
          product._id.toString() === action.payload._id.toString()
            ? { ...product, isActive: action.payload.isActive }
            : product
        ),
        error: {},
        loading: false,
      }

    case UPDATE_BULK_PRODUCTS_SUCCESS:
      return {
        ...state,
        error: {},
        updateBulkProductsLoading: false,
      }

    // all For Download
    case GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        productsForDownload: action.payload,
        error: {},
        productsForDownloadLoading: false,
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        error: {},
        loading: false,
      }

    case HARD_DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        error: {},
        loading: false,
      }

    case RESTORE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload._id.toString()
        ),
        error: {},
        loading: false,
      }

    case CREATE_DUPLICATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state.products],
        createProductError: null,
        loading: false,
        error: {},
        needRefrash: true,
      }

    case UNDO_REFRASH:
      return {
        ...state,
        needRefrash: false,
      }
    case CHECK_PRODUCT_NAME:
      return {
        ...state,
        loading: true,
      }
    case CHECK_PRODUCT_NAME_SUCCESS:
      return {
        ...state,
        checkProductNames: action.payload,
        loading: false,
      }

    case CHECK_PRODUCT_NAME_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //review
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        error: {},
        loading: false,
      }
    case GET_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        reviewsDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_REVIEW_SUCCESS:
      // console.log(state)
      // return {
      //   ...state,
      //   // reviews: action.payload,
      //   reviews: [ action.payload,...state?.reviews?.reviews],

      //   error: {},
      //   reviewLoad: false,
      // }
      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviews: [ action.payload,...state?.reviews?.reviews],
          // total: state.sales.total + 1,
        },

        // reviewsDetails: action.payload,
        error: {},
        reviewLoad: false,
      }

    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        // reviewsDetails: action.payload,
        reviews: {
          ...state.reviews,
          reviews: state.reviews?.reviews?.map(
            review => review._id === action.payload._id ?{...review,...action?.payload}:review
          ),
        },
        reviewLoad: false,
        error: {},
      }
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviews: state.reviews?.reviews?.filter(
            review => review._id !== action.payload._id
          ),
        },
        error: {},
        reviewLoad: false,
      }
      case PRODUCT_SINGLE_VIEW_STATE_CLEAR_SUCCESS:
        return {
          ...state,
          productDetails:{}
        
        }
    default:
      return state
  }
}

export default Products
