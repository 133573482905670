import {
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUP_DETAILS,
  GET_GROUP_DETAILS_SUCCESS,
  GET_GROUP_DETAILS_FAIL,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  CREATE_GROUP,
} from "./actionTypes"

const INIT_STATE = {
  groups: [],
  groupDetails: {},
  error: {},
  loading: false,
}

const Group = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS:
    case GET_GROUP_DETAILS:
    case UPDATE_GROUP:
    case CREATE_GROUP:
      return {
        ...state,
        loading: true,
      }

    case DELETE_GROUP_FAIL:
    case UPDATE_GROUP_FAIL:
    case CREATE_GROUP_FAIL:
    case GET_GROUP_DETAILS_FAIL:
    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        error: {},
        loading: false,
      }
    case GET_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        groupDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_GROUP_SUCCESS:
      // console.log(state)
      return {
        ...state,
        groups: action.payload,

        // groups: [...state?.groups].map(group =>
        //   group._id.toString() === action.payload._id.toString()
        //     ? { group, ...action.payload }
        //     : group
        // ),
        error: {},
        loading: false,
      }

    case DELETE_GROUP_SUCCESS:
      console.log(state?.groups?.groups)
      console.log(action?.payload)
      return {
        groups: {
          ...state?.groups,

          groups: state?.groups?.groups?.filter(
            group => group._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Group
