import axios from "axios"
import { Notification } from "components/Common/Notification"

//apply base url for axios
const API_URL_PROD = "https://api.adrakshoppe.com"
const API_URL_DEV = "http://64.227.174.186:4000"
const API_URL_LOCAL = "http://192.168.1.20:4000"

const axiosApi = axios.create({
  baseURL: API_URL_PROD,
})

axiosApi.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("token")

    if (token) {
      config.headers["Authorization"] =
        "Bearer " + sessionStorage.getItem("token")
    }
    return config
  },
  function (error) {
    return error
  }
)

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  err => {
    console.log(err.response.status)
    if (err.response.status == 401) {
      // sessionStorage.clear("token")
      // window.location.reload(false)
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    } else {
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    }
    return Promise.reject(err)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
