import { AvField } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { Col, Label, Row } from "reactstrap"
import UseImageUploader from "components/imageUploader/useImageUploader"
import PropTypes from "prop-types"

function Form({ formOption, updateData }) {
  const types = ["main", "sub"]
  const { bannerImagesPreview, setBannerImagesPreview } = formOption
  const [imageSetUp, setImageSetUp] = useState("")

  const handleImageSetUpChange = event => {
    setImageSetUp(event.target.value)
  }
  console.log(imageSetUp)

  return (
    <Row>
      <Col className="col-12 d-flex flex-wrap">
        <div className="col-4 mb-3 px-3">
          <AvField
            name="tag"
            label="Tag"
            type="text"
            value={updateData?.tag ?? ""}
          />
        </div>

        <div className="col-4 mb-4 px-3">
          <AvField
            name="priority"
            label="Priority"
            type="number"
            value={updateData?.priority ?? ""}
          />
        </div>
        <div className="col-4 mb-4 px-3">
          <AvField
            label="Type"
            type="select"
            name="type"
            value={updateData?.type}
            errorMessage="Select a banner type"
            onChange={handleImageSetUpChange}
            validate={{
              required: { value: true },
            }}
          >
            <option value="">Select a banner type</option>
            {types?.map((item, key) => (
              <option key={key} value={item}>
                {item}
              </option>
            ))}
          </AvField>
        </div>

        <div className="col-12 mb-4 px-3">
          <AvField
            name="link"
            label="Link"
            type="text"
            value={updateData?.link ?? ""}
          />
        </div>

        <Col md="6">
          <Label className="mb-2">Image</Label>
          <UseImageUploader
            imageSize={4}
            imagesPreview={bannerImagesPreview}
            setImagesPreview={setBannerImagesPreview}
            uploadUrl={"/categories"}
          />
        </Col>
        <div>
          {imageSetUp === "main" ? (
            <p className="text-muted">
              {`The baneer image should have a width of 1400 pixels and a height of 550 pixels.`}
            </p>
          ) : imageSetUp === "sub" ? (
            <p>
              {
                "The banner image should have a width of 450 pixels and a height of 300 pixels"
              }
            </p>
          ) : (
            ""
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Form

Form.propTypes = {
  formOption: PropTypes.object,
  updateData: PropTypes.object,
}
