import MetaTag from "components/Common/Meta-tag"
import React from "react"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container } from "reactstrap"
import TransactionsTable from "./DataTabels"

function Transactions() {
  return (
    <div>
      <MetaTag title={"All Transactions"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Transactions" />
        <Container fluid>
          <div className="container-fluid">
            <TransactionsTable />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Transactions
