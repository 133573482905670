import { debounce, map } from "lodash"
import Select from "react-select"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, FormGroup, Label } from "reactstrap"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import {
  getUserCartDetails,
  getAllProducts,
  addItemToUserCart,
} from "store/actions"
import OrderItemsDatatable from "../OrderItemsDatatable"
import { CurrencySwitch } from "hooks/Currency"
import AddCoupon from "./AddCoupon"
import { GroupTypesData } from "pages/groups-home/Crud/Create"

function AddProduct({ userId, paymentType, setPaymentType, isUpdate }) {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()

  const { products, productsLoading, userCartDetails, orderDetails } =
    useSelector(state => ({
      usersLoading: state.Users.loading,
      products: state.Products.products,
      productsLoading: state.Products.loading,
      userCartDetails: state.Users.userCartDetails,
      orderDetails: state.Orders.orderDetails,
    }))
  console.log(orderDetails)
  const [productSearchText, setProductSearchText] = useState("")

  const [selectedProductId, setSelectedProductId] = useState()
  const [selectedProductLabel, setSelectedProductLabel] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  const [varients, setVarients] = useState()

  useEffect(() => {
    dispatch(getAllProducts(1, "all", 10, productSearchText)) //products
  }, [dispatch, productSearchText])

  useEffect(() => {
    if (userId) {
      dispatch(getUserCartDetails(userId))
    }
  }, [dispatch, userId, selectedProduct])

  const debounceProductSearch = debounce(
    value => setProductSearchText(value),
    600
  )
  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedProductLabel(event.label)
    setSelectedProductId(event.value)
    setSelectedProduct(event)
  }
  // useEffect(() => {

  //   if (orderDetails?._id) {

  //     setSelectedProductLabel(`  ${orderDetails?.phone} - ${orderDetails.name}`);
  //     setSelectedProductId(orderDetails._id);
  //   }
  // }, [orderDetails]);

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result?.name ? "- " + result?.name : ""
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
          hasVarients: result?.hasVarients,
          varients: result?.varients,
          price: result?.price,
          offerPrice: result?.offerPrice,
        })
      ),
    },
  ]
  // font-family: "Roboto", sans-serif;

  const handleAddItemToCart = () => {
    console.log(selectedProduct)
    if (selectedProduct?.value) {
      const cartItem = {
        product: selectedProduct?.value,
        varient: selectedProduct?.hasVarients === true ? varients?._id : "",
        quantity: 1,
        operation: "add",
      }
      dispatch(addItemToUserCart(userId, cartItem))
    }
  }

  const handleVarients = () => {
    if (selectedProduct?.hasVarients === true) {
      return selectedProduct?.varients
    }
  }

  const handlePrice = () => {
    if (selectedProduct?.hasVarients === true) {
      if (varients?.primePrice) return varients?.primePrice
      else if (varients?.offerPrice) return varients?.offerPrice
      else return varients?.price
    } else if (selectedProduct?.offerPrice) return selectedProduct?.offerPrice
    else return selectedProduct?.price
  }

  useEffect(() => {
    if (selectedProduct?.hasVarients === true) {
      setVarients(selectedProduct?.varients[0])
    }
  }, [selectedProduct?.hasVarients])

  console.log(orderDetails)
  console.log(userCartDetails)
  const getTotalId = () => {
    if (isUpdate && orderDetails?._id) {
      return userCartDetails
      return {
        ...orderDetails,
        cartItems: orderDetails?.orderItems,
      }
    } else {
      return userCartDetails
    }
  }
  console.log(getTotalId())
  return (
    <div className="select-user">
      <Row>
        <Col lg={12}>
          <CardTitle>Product</CardTitle>
          <FormGroup className="col-12 mb-3">
            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
              <Select
                onInputChange={handleProductEnters}
                value={selectedProductId}
                placeholder={selectedProductLabel}
                onChange={handlerProductFinalValue}
                options={productOptions}
                classNamePrefix="select2-selection"
                isLoading={productsLoading}
              />
            </div>
          </FormGroup>
        </Col>
        {selectedProduct?.value && (
          <Col className="col-12">
            <div className="product-card-2">
              {selectedProduct?.image && (
                <div className="product-tumb">
                  <img src={selectedProduct?.image} alt="" />
                </div>
              )}
              <div className="product-details">
                <p>{selectedProduct?.label}</p>
                <div
                  className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {map(handleVarients(), (item, key) => (
                    <div key={key} className="me-3">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id={item.value}
                        autoComplete="off"
                        checked={varients?.value === item.value}
                        value={item.value}
                        onChange={e =>
                          setVarients({ ...item, value: e.target.value })
                        }
                      />
                      <label
                        className="btn btn-outline-info"
                        htmlFor={item.value}
                      >
                        {item.value}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="product-bottom-details">
                  <div className="product-price">₹ {handlePrice()}</div>

                  <div className="product-links">
                    <button
                      type="button"
                      className="col-12 btn btn-outline-info save-customer"
                      onClick={() => handleAddItemToCart()}
                    >
                      <i className="fa fa-shopping-cart"></i>&nbsp; Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
        <div className="mt-4">
          <Label>
            Order Items :{" "}
            {getTotalId()?.cartItems?.length ? (
              getTotalId()?.cartItems?.length
            ) : (
              <p className="text-warning mt-1">
                User does not have items in Cart yet
              </p>
            )}
          </Label>
          {getTotalId()?.cartItems?.length >= 1 && (
            <>
              <OrderItemsDatatable isUpdate={isUpdate} userId={userId} />
              <Row>
                <Col>
                  <Col className="text-end mb-2">
                    Items Total : {currency} {getTotalId()?.itemsPrice}
                  </Col>
                  <Col className="text-end mb-2">
                    Shipping : {currency} {getTotalId()?.shippingPrice}
                  </Col>
                  <Col className="text-end mb-2">
                    Coupon Discount : {currency} {getTotalId()?.couponDiscount}
                  </Col>
                  <Col className="text-end mb-2">
                    Discount : {currency} {getTotalId()?.totalDiscount}
                  </Col>
                  <Col className="text-end mb-2">
                    Gross Total : {currency} {getTotalId()?.totalPrice}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div className="mt-4">
          <Label>Coupon</Label>
          <AddCoupon userId={userId} />
        </div>
        <div className="mt-4">
          <Label>Payment Type</Label>
          <div>
            <button
              type="button"
              className={`me-2 btn tickbtn ${
                paymentType === "Online Payment"
                  ? "my-custom-success-soft btn-success"
                  : "btn-outline-light"
              }`}
              style={{ minWidth: "200px" }}
              onClick={() => setPaymentType("Online Payment")}
            >
              Online
              {paymentType === "Online Payment" && (
                <span className="corner">
                  <i className="bx bx-check" />
                </span>
              )}
            </button>
            <button
              type="button"
              className={`me-2 btn tickbtn ${
                paymentType === "Cash on Delivery"
                  ? "my-custom-success-soft btn-success"
                  : "btn-outline-light"
              }`}
              style={{ minWidth: "200px" }}
              onClick={() => setPaymentType("Cash on Delivery")}
            >
              Cash on Delivery
              {paymentType === "Cash on Delivery" && (
                <span className="corner">
                  <i className="bx bx-check" />
                </span>
              )}
            </button>
          </div>
        </div>
        {/* <Col className="col-4 d-flex flex-wrap">
          {selectedProduct && selectedProduct?.image && (
            <div className="mb-2 mt-4">
              <div className="d-flex align-items-center">
                <img
                  className="img-thumbnail"
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                  src={selectedProduct?.image}
                  alt="product"
                />
              </div>
            </div>
          )}
        </Col>

        {selectedProduct && selectedProduct?.hasVarients ? (
          <div
            className="btn-group mt-2 mb-2 mt-xl-0"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            {map(selectedProduct?.varients, (varient, key) => (
              <Fragment key={key}>
                <input
                  type="radio"
                  className="form-radio-outline"
                  name="btnradio"
                  id={varient._id + key}
                  autoComplete="off"
                  checked={productVarient === varient._id}
                  onChange={() => setProductVarient(varient._id)}
                />
                <Label className="mx-2" htmlFor={varient._id + key}>
                  {`${varient?.value} ${varient?.unit}` +
                    " - " +
                    (varient?.offerPrice
                      ? `₹ ${varient?.offerPrice}`
                      : `₹ ${varient?.price}`)}
                </Label>
              </Fragment>
            ))}
          </div>
         */}
      </Row>
    </div>
  )
}

export default AddProduct

AddProduct.propTypes = {
  userId: PropTypes.string,
  paymentType: PropTypes.any,
  setPaymentType: PropTypes.func,
  isUpdate: PropTypes.bool,
}
