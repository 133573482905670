import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select"

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import {
  getAllDistricts,
  getAllLsgs,
  getAllStates,
  getAllTaluks,
  getPromoters,
  updatePromoter,
} from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Promoters = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("all")
  const [totalItemLimit, setTotalItemLimit] = useState(10)

  const [stateSearch, setStateSearch] = useState("")
  const [stateSelect, setStateSelect] = useState("Search state")
  const [stateSelectId, setStateSelectId] = useState("")

  const [districtSearch, setDistrictSearch] = useState("")
  const [districtSelect, setDistrictSelect] = useState("Search District")
  const [districtSelectId, setDistrictSelectId] = useState("")

  const [talukSearch, setTalukSearch] = useState("")
  const [talukSelect, setTalukSelect] = useState("Search Taluk")
  const [talukSelectId, setTalukSelectId] = useState("")

  const [lsgSearch, setLsgSearch] = useState("")
  const [lsgSelect, setLsgSelect] = useState("Search Lsg")
  const [lsgSelectId, setLsgSelectId] = useState("")

  const { allPromoters, loading, taluks, districts, states, lsgs } =
    useSelector(state => ({
      allPromoters: state?.Users?.promotersList,
      loading: state.Users?.promoterLoad,
      states: state.Location.states,
      districts: state.Location.districts,
      taluks: state.Location.taluks,
      lsgs: state.Location.lsgs,
    }))

  console.log("allPromoters =>", allPromoters)
  const [searchText, setsearchText] = useState("")

  useEffect(() => {
    dispatch(
      getPromoters(
        page,
        searchText,
        sort,
        totalItemLimit,
        stateSelectId,
        districtSelectId,
        talukSelectId,
        lsgSelectId
      )
    )
  }, [
    dispatch,
    page,
    searchText,
    sort,
    totalItemLimit,
    stateSelectId,
    districtSelectId,
    talukSelectId,
    lsgSelectId,
  ])

  const totalPages = Math.ceil(allPromoters?.total / 10)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "ref_id",
      text: "Referral Id",
      sort: true,
    },

    {
      dataField: "promoterPercentage",
      text: "Promoter %",
      sort: true,
    },
    {
      dataField: "activeData",
      text: "Status",
      sort: true,
    },
    // {
    //   dataField: "isVerified",
    //   text: "Verified",
    //   sort: true,
    // },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    // {
    //   dataField: "address",
    //   text: "Address",
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleSwitchChange = (item, isActive) => {
    console.log(isActive)
    const finalData = {
      ...item,
      isActive: isActive,
    }

    dispatch(updatePromoter(finalData, item?._id, sort))
  }
  const promoterData = map(allPromoters?.promoters, (item, index) => ({
    ...item,
    promoterPercentage: <span>{item?.promoterPercentage}%</span>,

    action: (
      <>
        <Link to={`promoter/${item?._id}`} className="text-info">
          <i className="fas fa-eye font-size-15" />
        </Link>
      </>
    ),
    activeData: (
      <div className="form-check form-switch form-switch-md mb-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          onChange={e => {
            handleSwitchChange(item, e.target.checked)
          }}
          checked={item?.isActive}
        />
        <label className="form-check-label" htmlFor="customSwitchsizemd">
          {item?.isActive ? `Active` : `Inactive`}
        </label>
      </div>
    ),
  }))

  const debounceOrderSearch = debounce(value => setsearchText(value), 600)

  // const { SearchBar } = Search

  // console.log(allPromoters?.total)

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(e.target.value)
    }
  }

  const listChange = sortValue => {
    setSort(sortValue)
    // dispatch(getAllProducts(pageSend(), sortValue, pagination, ""))
    // dispatch(getPromoters(pageSend(), sortValue, searchText, totalItemLimit))
  }

  useEffect(() => {
    dispatch(getAllStates(stateSearch))
  }, [dispatch, stateSearch])
  const debounceStateSearch = debounce(value => setStateSearch(value), 600)
  const handleStateEnters = textEntered => {
    debounceStateSearch(textEntered)
  }
  function handlerStateFinalValue(event) {
    setStateSelect(event.label)
    setStateSelectId(event.value)
  }
  const StateOptions = [
    {
      label: "All state",
      id: "",
    },
    {
      options: (states?.total >= 1 ? states?.states : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]
  useEffect(() => {
    dispatch(getAllDistricts(districtSearch, "", page, 10))
  }, [dispatch, districtSearch])
  const debounceDistrictSearch = debounce(
    value => setDistrictSearch(value),
    600
  )
  const handleDistrictEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }
  function handlerDistrictFinalValue(event) {
    setDistrictSelect(event.label)
    setDistrictSelectId(event.value)
  }
  const DistrictOptions = [
    {
      label: "All district",
      id: "",
    },
    {
      options: (districts?.total >= 1 ? districts?.districts : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]
  useEffect(() => {
    dispatch(getAllTaluks(talukSearch, "", page, 10))
  }, [dispatch, talukSearch])
  const debounceTalukSearch = debounce(value => setTalukSearch(value), 600)
  const handleTalukEnters = textEntered => {
    debounceTalukSearch(textEntered)
  }
  function handlerTalukFinalValue(event) {
    setTalukSelect(event.label)
    setTalukSelectId(event.value)
  }
  const talukOptions = [
    {
      label: "All Taluks",
      value: "",
    },
    {
      options:
        taluks &&
        taluks?.total >= 1 &&
        taluks?.taluks?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]

  useEffect(() => {
    dispatch(getAllLsgs(lsgSearch, "", page, 10))
  }, [dispatch, lsgSearch])
  const debounceLsgSearch = debounce(value => setLsgSearch(value), 600)
  const handleLsgEnters = textEntered => {
    debounceDistrictSearch(textEntered)
  }
  function handlerLsgFinalValue(event) {
    setLsgSelect(event.label)
    setLsgSelectId(event.value)
  }
  const lsgOptions = [
    {
      label: "All Lsgs",
      value: "",
    },
    {
      options:
        lsgs &&
        lsgs?.total >= 1 &&
        lsgs?.lsgs?.map((result, index) => ({
          key: index,
          label: result?.name,
          value: result._id,
        })),
    },
  ]
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={promoterData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="">
                      <Col md={8} lg={5} className="">
                        <div
                          className="btn-group  mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            defaultChecked
                            onChange={() => listChange("all")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio1"
                          >
                            All
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            onChange={() => listChange("approved")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio2"
                          >
                            Approved
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio3"
                            autoComplete="off"
                            onChange={() => listChange("unapproved")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio3"
                          >
                            Unapproved
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio4"
                            autoComplete="off"
                            onChange={() => listChange("active")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio4"
                          >
                            Active
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio5"
                            autoComplete="off"
                            onChange={() => listChange("inactive")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio5"
                          >
                            Inactive
                          </label>
                        </div>
                      </Col>
                      <Col
                        sm="3"
                        md={4}
                        lg={2}
                        className="d-flex align-items-center"
                      >
                        <div className="search-box ms-2  d-inline-block">
                          <div className="position-relative">
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>

                      <Col
                        md={4}
                        className="d-flex align-items-center justify-content-center"
                        lg={2}
                      >
                        <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalItemLimit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </Col>
                      <Col
                        sm="6"
                        md={4}
                        className="d-flex align-items-center justify-content-center"
                        lg={3}
                      >
                        <div className="text-sm-end">
                          <Link to="/promoter/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Promoter
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="mb-2 padding-3" md={6} lg={3}>
                        <FormGroup className="w-350">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleStateEnters}
                              value={stateSelect}
                              placeholder={stateSelect}
                              onChange={handlerStateFinalValue}
                              options={StateOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 padding-3" md={6} lg={3}>
                        <FormGroup className="w-350">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleDistrictEnters}
                              value={districtSelect}
                              placeholder={districtSelect}
                              onChange={handlerDistrictFinalValue}
                              options={DistrictOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 padding-3" md={6} lg={3}>
                        <FormGroup className="w-350">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleTalukEnters}
                              value={talukSelect}
                              placeholder={talukSelect}
                              onChange={handlerTalukFinalValue}
                              options={talukOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2 padding-3" md={6} lg={3}>
                        <FormGroup className="w-350">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleLsgEnters}
                              value={lsgSelect}
                              placeholder={lsgSelect}
                              onChange={handlerLsgFinalValue}
                              options={lsgOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  key={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap floting-action"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  // onTableChange={handleTableChange}
                                  // {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          {allPromoters?.total !== 0 ? (
                            ""
                          ) : (
                            <p className="text-muted">No Promoter</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Promoters
